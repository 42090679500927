@import "../../partials/mixins";

.contact-section {
  padding: .5rem;
  position: relative;

  .email-phone {
    position: absolute;
    top: -5rem;
    left: 13rem;

    display: flex;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    gap: 4.5rem;

    .email,
    .phone {
      display: flex;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      gap: 1rem;

      border: 5px solid var(--footer-styling);
      color: var(--footer-styling);

      padding: .5rem 1rem;

      white-space: nowrap;

      &:hover {
        animation: pulse 550ms alternate infinite;
        -webkit-animation: pulse 550ms alternate infinite;
        -moz-animation: pulse 550ms alternate infinite;
      }
    }
  }

  .contact-section-company-owner {
    @include TitleTextH2;

    text-align: center;
    font-size: 1rem;
    color: var(--general-bg-color);

    img {
      max-width: 7rem;
      border-radius: 50%;
      margin-bottom: .5rem;
    }
  }

  .contact-section-contact {
    margin-top: .5rem;

    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    gap: 1rem;
  }
}

@media (max-width: 1100px) {
  .contact-section {
    .email-phone {
      position: absolute;
      top: -10rem;
      left: -2.5rem;
  
      flex-direction: column;
      -ms-flex-direction: column;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      gap: 1rem;
    }
  }
}


@keyframes pulse {
  from {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
  }

  to {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
  }
}