@import "../../partials/mixins";

.company-services-container {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;

  border-left: 1px solid var(--footer-styling);
  border-right: 1px solid var(--footer-styling);

  padding-inline: 1rem;

  @include NormalText;
  letter-spacing: normal;
  color: var(--footer-styling);

  .one-company-service {
    list-style: none;
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .company-services-container {
    border-top: 1px solid var(--footer-styling);
    border-bottom: 1px solid var(--footer-styling);

    border-left: none;
    border-right: none;

    padding-block: 1rem;
  }
}

@media (max-width: 500px) {
  .company-services-container {
    flex-direction: column;
      -ms-flex-direction: column;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
  }
}