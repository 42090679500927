@import "../../partials/mixins";

.prefooter-section {
  background-color: var(--footer-bg-color);
  padding-inline: 1rem;

  .prefooter-contact-services-opening-hours {
    display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
    justify-content: space-evenly;
    align-items: center;
      -webkit-align-items: center;

    padding: .5rem 0 .5rem;
  }
}

@media (max-width: 1100px) {
  .prefooter-section {

    .prefooter-contact-services-opening-hours {
      flex-direction: column;
        -ms-flex-direction: column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
    }
  }
}