@import "../../partials/mixins";

header {
  background: url("../../assets/images/backgrounds/headerBathroom.jpg") no-repeat center/cover fixed;
  
  height: 80vh;
  
  .overlay {
    background-color: var(--overlay-bg-color);
    font-family: var(--title-text);
    height: 100%;

    .header-general-title {
      @include FlexJustifyItemCenter;
      flex-direction: column;
        -ms-flex-direction: column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        
      height: 100%;

      color: var(--general-bg-color);
      text-transform: uppercase;
      text-align: center;

      h1 {
        border-bottom: 1px solid var(--general-bg-color);
      }

      span {
        padding: .3rem 0;
        font-size: 1.1rem;
      }
    }
  }
}

@media (max-width: 849px) {
  header {

    .header-general-title {

      h1 {
        line-height: 2.1rem;
        padding-bottom: .3rem;
      }
    }
  }
}

@media (max-width: 820px) {
  header {
    margin-bottom: 0;

    .header-text-container {

      h1 {
        line-height: 2.1rem;
        padding-bottom: .3rem;
      }
    }
  }
}