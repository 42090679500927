// Display flex center
@mixin FlexJustifyItemCenter {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}
/********************************************/

// Text styles
@mixin TitleTextH1 {
  color: var(--general-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;

  font: {
    family: var(--title-text);
    size: 1.5rem;
  }
}

@mixin TitleTextH2 {
  color: var(--general-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;

  font: {
    family: var(--title-text);
    size: 1.35rem;
  }
}

@mixin TitleTextH3 {
  color: var(--general-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;

  font: {
    family: var(--title-text);
    size: 1.2rem;
  }
}

@mixin NormalText {
  color: var(--text-color);
  letter-spacing: .6px;
  line-height: 1.4rem;

  font: {
    family: var(--normal-text);
    size: 1.07rem;
  }
}

/********************************************/

// Clamp 
@mixin clamp($property, $min-size, $scaler, $max-size, $min-size-left: false, $scaler-left: false, $max-size-left: false) {
  @if $min-size-left ==false {
    // Set MAX values
    #{$property}: $max-size;
    #{$property}: #{“min(max(#{$min-size},
    #{$scaler}),
    #{$max-size})”
  }

  ;
  #{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size});
}

@else if $min-size-left ==0 or $min-size-left ==auto {
  // Set MAX values
  #{$property}: $max-size $max-size-left;
  #{$property}: #{“min(max(#{$min-size},
  #{$scaler}),
  #{$max-size})”
}

$min-size-left;
#{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size}) $min-size-left;
}

@else {
  // Set MAX values
  #{$property}: $max-size $max-size-left;
  #{$property}:#{“min(max(#{$min-size},
  #{$scaler}),
  #{$max-size})”
}

#{“min(max(#{$min-size-left},
#{$scaler-left}),
#{$max-size-left})”
}

;
// add second CLAMP and #{} to SASS variables
#{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size}) clamp(#{$min-size-left}, #{$scaler-left}, #{$max-size-left});
}
}